<template>
  <div>
    <span class="open-face" @click="openFace()">活体</span>
    <span class="open-face-callback"
      >活体回调数据： {{ this.openFaceCallbackData }}</span
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      openFaceCallbackData: "",
    };
  },
  created() {
    const _t = this;
    window.openFaceCallback = (data) => {
        _t.openFaceCallbackData = data;
    };
  },
  methods: {
    openFace() {
      this.$H5Object.openFace();
    },
  },
};
</script>

<style scoped>
.open-face {
  position: absolute;
  width: 200px;
  height: 88px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -44px;
  line-height: 88px;
  text-align: center;
  color: #fff;
  background: green;
}
.open-face-callback {
  padding: 80px;
  position: absolute;
  top: 20px;
}
</style>